import {Route, BrowserRouter, Link, Redirect} from "react-router-dom";
import MappingTesting from "./components/MappingTesting";
import Sync from "./components/Sync";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <section>
          <div className="terminal-nav">
            <header className="terminal-logo">
              <div className="logo terminal-prompt">SETelecom Playground
              </div>
            </header>
            <nav className="terminal-menu">
              <ul>
                <li><Link to="/mapping">Mapping</Link></li>
                <li><Link to="/sync">Sync</Link></li>
              </ul>
            </nav>
          </div>
        </section>

        <Route exact path="/" render={() => (<Redirect to="/mapping" />)} />
        <Route path="/mapping" exact component={MappingTesting} />
        <Route path="/sync" exact component={Sync} />

      </BrowserRouter>
    </div>
  );
}

export default App;
