import {useState} from "react";

export default function Debug(props) {
  const {debug} = props;

  const [show, setShow] = useState(false);

  console.log('debug', debug);

  return (
    <div>
      <section>
        <header>
          <h2 onClick={() => setShow(!show)}>Debug</h2>
        </header>

        {show && (
          <div>
            <DebugTable caption="Seats Debug Messages" messages={debug.seat} />
            <DebugTable caption="Transcribe Debug Messages" messages={debug.tr} />
            <DebugTable caption="Recordings Debug Messages" messages={debug.rec} />
            <DebugTable caption="911 Debug Messages" messages={debug.e911} />
            <DebugTable caption="Registrations Debug Messages" messages={debug.re} />
            <DebugTable caption="TollFree Debug Messages" messages={debug.tf} />
            <DebugTable caption="DID Debug Messages" messages={debug.did} />
          </div>
        )}
      </section>
    </div>
  );
}


function DebugTable(props) {
  const {caption, messages} = props;

  // console.log(caption, messages);
  console.log("caption", caption);
  console.log("messages", messages);
  console.log(typeof messages);
  console.log(Array.isArray(messages));
  // console.log(Object.keys(messages));
  // console.log(messages.length);

  if (messages == null) {
    console.log("messages is null");
    return null;
  }

  return (

      <table>
        <caption>
          {caption}
        </caption>
        <thead>
        <tr>
          <th>UID</th>
          <th>Message</th>
        </tr>
        </thead>
        <tbody>
        {messages.map((item) => (
          <tr>
            <td>{item.uid}</td>
            <td>{item.message}</td>
          </tr>
        ))}
        </tbody>
      </table>

  );
}