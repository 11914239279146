import {useState} from "react";
import axios from "axios";
import Debug from "./Debug";

export default function MappingTesting(props) {
  const [domain, setDomain] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  function send() {

    setError(null);
    setResult(null);

    let data = {
      domain: domain,
    }

    axios.post("/api/test-map", data)
      .then(function (response) {
        // handle success
        setResult(response.data);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      })
      .then(function (response) {
        // always executed
      });
  }

  return (
    <div>
      <section>
        <header>
          <h2>Mapping Testing</h2>
        </header>
        <input type="text" placeholder="Domain" value={domain} onChange={(e) => setDomain(e.target.value)} />
        <button className="btn btn-default" disabled={domain === ""} onClick={() => send()}>Get Billing Data</button>
      </section>
      { result && (
        <div>
          <hr />
          <section>
            <header>
              <h2>Result</h2>
            </header>
            <table>
              <caption>
                Billing Details for {result.domain} ({result.reseller})
              </caption>
              <thead>
              <tr>
                <th>Name</th>
                <th>Dev</th>
                <th>Seat</th>
                <th>Tr</th>
                <th>Conf</th>
                <th>CCA</th>
                <th>CCS</th>
                <th>REC</th>
                <th>DID</th>
                <th>TF</th>
                <th>RE</th>
                <th>CH</th>
                <th>AA</th>
                <th>Fax</th>
                <th>SMS</th>
                <th>911</th>
                <th>Call Queue</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{result.domain}</td>
                <td>{result.dev}</td>
                <td>{result.seat}</td>
                <td>{result.tr}</td>
                <td>{result.conf}</td>
                <td>{result.cca}</td>
                <td>{result.ccs}</td>
                <td>{result.rec}</td>
                <td>{result.did}</td>
                <td>{result.tf}</td>
                <td>{result.re}</td>
                <td>{result.ch}</td>
                <td>{result.aa}</td>
                <td>{result.fax}</td>
                <td>{result.sms}</td>
                <td>{result.e911}</td>
                <td>{result.callQueue}</td>
              </tr>
              </tbody>
            </table>
          </section>
          {result.debug && (
            <Debug debug={result.debug} />
          )}

        </div>
      )}
      { error && (
        <div>
          <hr />
          <span>{error.toString()}</span>
        </div>
      )}
    </div>
  );
}