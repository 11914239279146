import {useState} from "react";
import axios from "axios";

export default function Sync(props) {

  const [domain, setDomain] = useState("");
  const [dryRun, setDryRun] = useState(true);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  function send() {

    setError(null);
    setResult(null);

    let data = {
      domain: domain,
      dryRun: dryRun,
    }

    axios.post("/api/sync", data)
      .then(function (response) {
        // handle success
        setResult(response.data);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      })
      .then(function (response) {
        // always executed
      });
  }

  return (
    <div>
      <section>
        <form>
          <fieldset>
            <legend>Sync Parameters</legend>
            <div className="form-group">
              <label htmlFor="domain">Domain:</label>
              <input id="domain" name="domain" type="text" required="" placeholder="Domain Name"
                     value={domain} onChange={(e) => setDomain(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="dryrun">Do not apply changes:</label>
              <input htmlFor="dryrun" type="checkbox" id="dryrun" defaultChecked={dryRun} onChange={() => setDryRun(!dryRun)} />
            </div>
            <div className="form-group">
              <button className="btn btn-default" type="button" role="button" name="submit" id="submit"
                      onClick={() => send()}>Sync</button>
            </div>
          </fieldset>
        </form>
        { result && (
          <div style={{whiteSpace: "pre"}}>
            {result.result.split("\n").map(function(item) {
              return (
              <span>
              {item}
              <br/>
              </span>
              )
            })}
          </div>
        )}
        { error && (
          <div>
            <hr />
            <span>{error.toString()}</span>
          </div>
        )}
      </section>
    </div>
  )
}
